import axios, { AxiosError } from "axios";
import config from "../config";
import { useLoginStore } from "@/stores/login";

const instance = axios.create({
  baseURL:
    location.protocol +
    "//" +
    (config.isdev
      ? config.devhost + ":" + config.devport
      : config.host + ":" + config.port),
  timeout: 30000,
});

export const payloginInstance = axios.create({
  baseURL: "https://" + config.loginAndPayHost + ":" + config.loginAndPayPort,
  timeout: 1000,
});

payloginInstance.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (err) => {
    console.error("未获捕的网络错误响应", err);
    return Promise.reject(err);
  }
);

// 添加请求拦截器
instance.interceptors.request.use(
  function (configs) {
    // 在发送请求之前做些什么
    //console.log("发送头", config);
    //if (config.url != "/beebackend/index/login") {
    configs.data.__fd = config.wsfd;
    try {
      //@ts-ignore
      if (getCurrentPages) {
        const page = getCurrentPages();
        if (page.length > 0) {
          const cur = page[page.length - 1];
          configs.data._page = cur.route;
        }
      }
    } catch (error) {
      //do noting
    }

    if (config.token) {
      configs.headers.Authorization = "Bearer " + config.token;
    }
    //}
    return configs;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

export class BusinessErrors extends Error {
  public code: string;
  public data: any;
  public info: string;
  public infoAndData: string;
  constructor(err: AxiosError) {
    super(err as any);
    //@ts-ignore
    this.code = err.response!.data.error.code;
    //@ts-ignore
    this.info = err.response!.data.error.info;
    //@ts-ignore
    this.data = err.response!.data.data || "";
    //@ts-ignore
    const data = err.response!.data.data;
    //@ts-ignore
    this.infoAndData =
      //@ts-ignore
      err.response!.data.error.info +
      (typeof data == "string" ? data : JSON.stringify(data));
  }
}

export class NologinEror extends BusinessErrors {
  public curPage: string;
  constructor(e: any) {
    super(e);
    this.curPage = encodeURIComponent(
      (location.hash as string).replace("#", "")
    );
  }
}

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    typeof response.data == "string" && alert(response.data);
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    const tips = response.data.tips;
    if (Array.isArray(tips)) {
      for (const element of tips) {
        const box = document.createElement("div");
        const text = document.createElement("div");
        const btn = document.createElement("button");

        btn.onclick = () => {
          document.body.removeChild(box);
        };

        box.style.width = "89.3vw";
        box.style.boxSizing = "border-box";
        box.style.padding = "8vw";
        box.style.background = "white";
        box.style.position = "fixed";
        box.style.left = "50%";
        box.style.top = "50%";
        box.style.borderRadius = "2.6vw";
        box.style.boxShadow = "0px 10px 20px 0px rgba(0, 0, 0, 0.1)";
        box.style.zIndex = "99999999";
        box.style.transform = "translate(-50%, -50%)";

        text.style.fontSize = "4.2vw";
        text.style.lineHeight = "7.4vw";
        text.innerText = element;

        btn.style.width = "100%";
        btn.style.height = "11vw";
        btn.style.borderRadius = "2.6vw";
        btn.style.background = "#E9AD02";
        btn.style.color = "white";
        btn.style.fontSize = "4.2vw";
        btn.style.fontWeight = "bold";
        btn.style.border = "none";
        btn.innerText = "好的";
        btn.style.marginTop = "5.3vw";

        box.appendChild(text);
        box.appendChild(btn);

        document.body.appendChild(box);
      }
    }

    return response.data;
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    if (error.response.status == 500) {
      //@ts-ignore
      __global500error(error.response.data);
    }
    if (error.response.status == 401) {
      if (error.response.data?.error.code == "100") {
        const { data } = error.response.data;
        saveCoustomBg(data);
      }
    }
    //未登录
    if (error.response.status == 403 || error.response.status == 401) {
      const err = error.response.data.error;
      if (error.response.data.error) {
        if (err) {
          if (err.code == "100" || err.code == "101") {
            const loginstore = useLoginStore();
            loginstore.logout();
            return Promise.reject(new NologinEror(error));
          }
        }
      }
    }

    if (error.response.status == 403) {
      if (error.response.data.error) {
        return Promise.reject(new BusinessErrors(error));
      }
    }
    return Promise.reject(error);
  }
);

interface CustomBg {
  login_name: string;
  login_title: string;
  login_pt: string;
  choose_pt: string;
  setbg: boolean;
}
export const saveCoustomBg = (data: CustomBg) => {
  if (
    data?.setbg ||
    data.login_name ||
    data.login_title ||
    data.login_pt ||
    data.choose_pt
  ) {
    localStorage.setItem(
      "customBg",
      JSON.stringify({
        login_name: data.login_name,
        login_title: data.login_title,
        login_pt: data.login_pt,
        choose_pt: data.choose_pt,
      })
    );
  }
};

export const getCoustomBg = () => {
  const infostring = localStorage.getItem("customBg");
  const info: CustomBg = infostring
    ? JSON.parse(infostring)
    : {
        login_name: "",
        login_title: "",
        login_pt: "",
        choose_pt: "",
      };
  return info;
};

export default instance;
